import { Component, Inject, OnInit } from '@angular/core';
import { Options } from 'angular2-notifications';
import { Router, NavigationEnd } from '@angular/router';
import { TokenService } from './services/token.service';
import { FC_ENV, Environment } from './models';
declare let gtag: Function;

@Component({
    // tslint:disable-next-line
    selector: 'body',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

    public userId = null;
    public notifcationOptions: Options = {
        position: ['top', 'right'],
        timeOut: 2500,
        animate: 'scale',
        showProgressBar: true,
        pauseOnHover: true,
    };

    constructor(
        @Inject(FC_ENV) public env: Environment,
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-43056743-3',
                    {
                        'page_path': event.urlAfterRedirects
                    }
                );
            }
        });
    }

    ngOnInit() {

        // make new routes automatically scroll to top
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }
}
