import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgxStripeModule } from 'ngx-stripe';
import { CommonModule } from '@angular/common';
import { BrowserXhr, HttpModule, Http } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { HttpClientModule } from '@angular/common/http';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GoogleAnalyticsService } from './google-analytics.service';

import { FC_ENV } from '../app/models/environment.model';
import { environment } from '../environments/environment';

import { Role } from '../app/models/role.model';
import { User } from '../app/models/user.model';
// import {ShareButtonsModule} from 'ngx-sharebuttons';

import { EditorModule } from '@tinymce/tinymce-angular';

// App-level Components
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login';
import { NotFoundComponent } from './views/not-found';
import { LayoutComponent } from './layout/layout.component';
import {
	AppAside,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
} from './components';

const APP_COMPONENTS = [
	AppAside,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	LoginComponent,
	NotFoundComponent,
	LayoutComponent,
];


// App-level Directives
import {
	AsideToggleDirective,
	NAV_DROPDOWN_DIRECTIVES,
	SIDEBAR_TOGGLE_DIRECTIVES,
} from './directives';

const APP_DIRECTIVES = [
	AsideToggleDirective,
	NAV_DROPDOWN_DIRECTIVES,
	SIDEBAR_TOGGLE_DIRECTIVES,
];

// App-level Services
import { AuthGuard } from './guards/auth-guard.service';
import { RoleGuard } from './guards/role-guard.service';
import { TokenService } from './services/token.service';
import { HttpService } from './services/http.service';
import { LoginService } from './services/login.service';
import { ProductService } from './services/product.service';

// Routing module
import { AppRoutingModule } from './app.routing';

// 3rd party components
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { BsPopoverModule } from 'ngx-bootstrap/popover';
// import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		EditorModule,
		FormsModule, ReactiveFormsModule,
		HttpModule,
		AppRoutingModule,
		HttpClientModule,
		NgProgressModule,
		NgxStripeModule.forRoot(),
		NgProgressHttpModule,		// BsDropdownModule.forRoot(),
		RouterModule,
		// TabsModule.forRoot(),
		SimpleNotificationsModule.forRoot(),
	],
	declarations: [
		AppComponent,
		...APP_COMPONENTS,
		...APP_DIRECTIVES,
	],
	providers: [
		{ provide: FC_ENV, useValue: environment },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		GoogleAnalyticsService,
		TokenService,
		{
			provide: User,
			useClass: User,
			deps: [Router, TokenService],
		},
		{
			provide: AuthGuard,
			useClass: AuthGuard,
			deps: [Router, TokenService],
		},
		{
			provide: RoleGuard,
			useClass: RoleGuard,
			deps: [Router, TokenService],
		},
		{
			provide: HttpService,
			useClass: HttpService,
			deps: [Http, TokenService],
		},
		{
			provide: LoginService,
			useClass: LoginService,
			deps: [HttpService, FC_ENV],
		},
		{
			provide: ProductService,
			useClass: ProductService,
			deps: [HttpService, FC_ENV],
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
