import { PaginatedResponse } from './paginated-response.model';

export class Image {
  public id: number;
  public created_at: Date;
  public files: any;
  public updated_at: Date;
  public url: string;
  public title: string;
  public description: string;
  public data?: any;
}


export class ImagePaginatedResponse extends PaginatedResponse {
  public data: Image[];
}
