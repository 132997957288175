export class ErrorResponse {
  public status: number;
  public message: string;
  public errors: {
    [key: string]: string[];
  };
}

export class ErrorMessage {
  public status: number;
  public statusText: string;
  public message: string;
  public key?: string;
}
