import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map'
import 'rxjs/Rx';

import { User } from '../models/user.model';

import { ErrorResponse, ErrorMessage } from '../models/error.model';
import { TokenService } from './token.service';


@Injectable()
export class HttpService {
    private requestHostname: string = window ? window.location.origin : '';
    private requestUrl = '';
    private requestQuery = '';
    private version = 1;
    public user = null;

    constructor(
        private http: Http,
        private tokenService: TokenService
    ) { }

    /**
     * Wrapper for Angular's Http.head(), with additional headers added.
     */
    public head(url: string): Observable<any> {
        return this.request('head', url);
    }

    /**
     * Wrapper for Angular's Http.get(), with additional headers added.
     */
    public get(url: string): Observable<any> {
        return this.request('get', url);
    }

    /**
     * Wrapper for Angular's Http.post(), with additional headers added.
     */
    public post(url: string, data: any): Observable<any> {
        return this.request('post', url, data);
    }

    /**
     * Wrapper for Angular's Http.put(), with additional headers added.
     */
    public put(url: string, data: any): Observable<any> {
        return this.request('put', url, data);
    }

    /**
     * Wrapper for Angular's Http.patch(), with additional headers added.
     */
    public patch(url: string, data: any): Observable<any> {


        // public uploadFile(fileToUpload: File) {
        //   const _formData = new FormData();
        //   _formData.append('file', fileToUpload, fileToUpload.name);
        //   return<any>post(UrlFileUpload, _formData); //note: no HttpHeaders passed as 3d param to POST!
        //                                            //So no Content-Type constructed manually.
        //                                            //Angular 4.3/4.x does it automatically.
        // }

        // const _formData = new FormData();
        // _formData.append('file', data.images[0].files, data.images[0].files.name);
        // if (args[0].images) {
        // console.log('data = ', data);
        // let _formData = new FormData();
        // // _formData.append('campaign', data);
        // _formData.append("Name", 'myFile');
        // _formData.append("MyFile", data.images[0].files);
        // data.images[0].files = _formData;
        // data = _formData;
        // console.log('_formData = ', _formData);
        // } else {
        // let body = args;
        // }
        // data.images[0].files = _formData;

        return this.request('patch', url, data);
    }

    /**
     * Wrapper for Angular's Http.delete(), with additional headers added.
     */
    public delete(url: string): Observable<any> {
        return this.request('delete', url);
    }

    /**
     * Wrapper for Angular's Http.options(), with additional headers added.
     */
    public options(url: string): Observable<any> {
        return this.request('options', url);
    }

    /**
     * Override the host for the request, in case it is cross-origin.
     */
    public hostname(hostname: string): this {
        this.requestHostname = hostname.match(/^(http|\/\/)/) ? hostname : `//${hostname}`;

        return this;
    }

    /**
     * Turn a key/value pair object into a query string and append to the request URL.
     */
    public query(query: { [key: string]: string | number | boolean }): this {
        this.requestQuery = this.buildQueryString(query);

        return this;
    }

    /**
     * Sets the Version to be sent to the API in an Accepts header, in case you want to
     * override the default version. This must be called on every request you make, otherwise
     * the default is used on all requests.
     */
    public needsVersion(version: number = this.version): this {
        this.version = version;

        return this;
    }

    /**
     * Builds a query string from the key/value pairs in an object.
     */
    public buildQueryString(query: any): string {
        const vars: Array<string> = [];
        _.forEach(query, (val: string, key: string) => {
            if (val && typeof val !== 'object') {
                vars.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
            }
        });

        if (vars.length) {
            return '?' + vars.join('&');
        }

        return '';
    }

    /**
     * Returns the headers to be used in request.
     */
    public getHeaders(): Headers {
        const headers: Headers = new Headers();

        // Set the Accepts header
        // headers.set('Accept', `application/vnd.spendindie.v${this.version}+json`);
        headers.set('Accept', 'application/json');

        // Set the Content-Type as JSON for all requests to our API
        headers.set('Content-Type', 'application/json');

        // For uploading files
        // headers.append('Content-Type', 'multipart/form-data');

        // Set Authorization header
        const token: string = this.tokenService.get();
        headers.delete('Authorization');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    }

    /**
     * Return the URL with hostname/url/query string.
     */
    public getUrl(url: string): string {
        // Allow a / prefix
        url = url.replace(/^\//, '');

        if (url.indexOf('api.php') !== -1) {
            return url;
        }
        // Add version number to the URL, except for /login endpoint and login/check endpoint
        if (url !== 'login' && url !== 'session-login') {
            url = `v${this.version}/${url}`;
        }

        return `${this.requestHostname}/${url}${this.requestQuery}`;
    }

    /**
     * Wrapper for Angular's Http methods. Calls the appropriate method, and optionally passes
     * the data object if it is passed to a POST, PUT, or PATCH. Appends any custom headers
     * such as Authorization, Accepts, etc.
     */
    private request(method: string, url: string, ...args: any[]): Observable<any> {
        const requestHeaders: Headers = this.getHeaders();
        const requestUrl: string = this.getUrl(url);
        this.reset();
        if (method in this.http) {
            return this.http[method]
                .call(this.http, requestUrl, ...args, { headers: requestHeaders })
                .map((res: Response) => {
                    this.reset();
                    return res.text().match(/^{/) ? res.json() : res.text();
                })
                .catch((response: Response) => {
                    return throwError(this.error(response));
                });
        }

        return Observable.empty();
    }

    /**
     * Determine if the user has a given role
     * @returns {boolean}
     */
    public hasRole(role: string): boolean {
        if (!this.user || this.user.role.name !== role) {
            return false;
        }
        return true;
    }

    /**
     * Return a string list of the errors from the server.
     */
    public error(response: Response): ErrorMessage {
        let body: ErrorResponse;
        let message: any = 'Server responded with an error.';
        let errorKey = null;

        try {
            body = response.json();
        } catch (e) {
            message = 'Server did not respond with JSON.';
        }

        if (response.status === 404) {
            message = 'Resource not found.';
        }

        else if (_.get(body, 'errors')) {
            const messages: string[] = _.map(body.errors, (e: Array<string>) => _.join(e, ' '));
            message = _.join(messages, ' ');
        }

        else if (_.get(body, 'message')) {
            message = body.message;
        }

        else {
            message = body;
        }

        if (typeof message === 'object') {
            var str = '';
            for (var key in message) {
                let value = message[key];
                str += value + ' ';
            }
            message = str;
        }
        // else {
        //     message = '';
        //     for (let key in body) {
        //         let value = body[key];
        //         value + '. ';
        //         errorKey = key;
        //     };
        // }

        return {
            status: response.status,
            statusText: response.statusText,
            message: message,
            key: errorKey
        };
    }

    /**
     * After a request completes, reset a few fields.
     * With Angular's DI, the next consumer would get the same fields.
     *
     * TODO - Fix race condition if two requests begin simultaneously.
     * May have to ditch return this and just set them since the instance is reused.
     */
    private reset(): void {
        this.requestHostname = window ? window.location.origin : '';
        this.requestUrl = '';
        this.requestQuery = '';
        this.version = 1;
    }
}
