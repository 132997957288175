import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { TokenService } from '../services/token.service';
import { User } from '../models/user.model';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(
        public router: Router,
        public tokenService: TokenService,
        public user: User
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean {

        // this will be passed from the route config on the data property
        const expectedRole = route.data.role;
        const redirect = route.data.redirect ? route.data.redirect : 'login';
        const token = localStorage.getItem('token');

        this.user = this.tokenService.getUser();

        if (
            this.tokenService.valid()
            && (
                typeof expectedRole == 'undefined'
                || (
                    typeof expectedRole !== 'undefined'
                    && this.user.role.name == expectedRole
                )
            )
        ) {
            return true;
        }
        this.router.navigate([redirect]);
        return false;
    }
}
