import { Injectable, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { HttpService } from './http.service';
import { Product, ProductPaginatedResponse } from '../models';

import { FC_ENV, Environment } from '../models';
import { TokenService } from '../services/token.service';
import { User } from '../models/user.model';

@Injectable()
export class ProductService {
    public omit: Array<string> = [
        'created_at',
        'image',
        'price',
        'updated_at',
        'user',
    ];

    constructor(
        protected http: HttpService,
        @Inject(FC_ENV) protected environment: Environment,
        protected tokenService: TokenService,
        protected user: User
    ) {}

    public ngOnInit(): void {
        this.user = this.tokenService.getUser();
    }

        /**
        * Request the products.
        */
        public all(): Observable<ProductPaginatedResponse> {
            return this.http
            .hostname(this.environment.api)
            .get('products?with=user');
        }

        /**
        * Request the products.
        */
        public my(): Observable<ProductPaginatedResponse> {
            return this.http
            .hostname(this.environment.api)
            .get(`products?where=user_id=${this.user.id}`);
        }

        /**
        * Request a given product.
        */
        public one(id: number, relationships?: string): Observable<Product> {
            return this.http
            .hostname(this.environment.api)
            .get(`products/${id}?with=${relationships}`);
        }

        /**
        * Store the given user.
        * Figures out if we should POST or PATCH whether there is an ID.
        */
        public store(product: Product): Observable<Product> {
            if (product.id > 0) {
                return this.patch(product);
            }

            return this.post(product);
        }

        /**
        * Create a product.
        */
        public post(product: Product): Observable<Product> {
            return this.http
            .hostname(this.environment.api)
            .post('products', product);
        }

        /**
        * Update a product.
        */
        public patch(product: Product): Observable<Product> {
            return this.http
            .hostname(this.environment.api)
            .patch(`products/${product.id}`, _.omit(product, this.omit));
        }

        /**
        * Delete a product.
        */
        public delete(product: Product): Observable<null> {
            return this.http
            .hostname(this.environment.api)
            .delete(`products/${product.id}`);
        }
    }
