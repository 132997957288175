import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpService } from './http.service';
import { TokenResponse } from '../models/token.model';
import { LoginRequest } from '../models/login.model';

import { FC_ENV, Environment} from '../models';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { toPromise } from 'rxjs/operator/toPromise';
import { Session } from 'selenium-webdriver';

@Injectable()
export class LoginService {
    constructor(protected http: HttpService,
        @Inject(FC_ENV) protected env: Environment) {
    }

    /**
     * Authenticate a user. Requires an email/password object.
     */
    public authenticate(form: LoginRequest): Observable<TokenResponse> {
        return this.http
            .hostname(this.env.api + '/v1')
            .post('login', form);
    }

    /**
     * Login as the user with the specified ID (for admins only)
     */
    public loginAsUser(id: number): Observable<TokenResponse> {
        return this.http
            .hostname(this.env.api)
            .get(`login-as-user/${id}`);
    }
}
