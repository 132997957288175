import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Components
import { LayoutComponent } from './layout';
import { LoginComponent } from './views/login';
import { NotFoundComponent } from './views/not-found';

// Guards
import { AuthGuard } from './guards/auth-guard.service';


// Note: Add dashboard routes to the DashboardRoutingModule
export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './views/home/home.module#HomeModule'
            },
            {
                path: 'emails',
                loadChildren: './views/emails/email.module#EmailModule'
            },
            {
                path: 'landing',
                loadChildren: './views/landing/landing.module#LandingModule'
            },
            {
                path: 'my',
                loadChildren: './views/my/my.module#MyModule'
            },
            {
                path: 'payments',
                loadChildren: './views/payments/payment.module#PaymentModule'
            },
            {
                path: 'posts',
                loadChildren: './views/posts/post.module#PostModule'
            },
            {
                path: 'subscriptions',
                loadChildren: './views/subscriptions/subscription.module#SubscriptionModule'
            },
            {
                path: 'users',
                loadChildren: './views/users/user.module#UserModule'
            },
            {
                path: 'webinar',
                loadChildren: './views/webinar/webinar.module#WebinarModule'
            },
        ]
    },

    // 404
    {
        path: '**',
        component: NotFoundComponent,
    },
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
