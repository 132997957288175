// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { Environment } from '../app/models/environment.model';

export const environment: Environment = {
    api: 'https://makeover-api.marriedgames.org',
    app_name: 'MarriedGames.org Marriage Makeover',
    app_url: 'https://makeover.marriedgames.org',
    production: false,
    // stripe_client_id: 'ca_CxsP9cW6l2OQUsY1poNHQZTazfnnG13y',
    stripe_key: 'pk_live_Qpq5oFZx6LNT5j7hPLBHJzal',
};
