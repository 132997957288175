import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
    selector: 'app-sidebar-footer',
    templateUrl: './app-sidebar-footer.component.html'
})

export class AppSidebarFooter implements OnInit {
    constructor(
        private el: ElementRef
    ) { }

    public ngOnInit(): void {
        const nativeElement: HTMLElement = this.el.nativeElement,
            parentElement: HTMLElement = nativeElement.parentElement;

        // move all children out of the element
        while (nativeElement.firstChild) {
            parentElement.insertBefore(nativeElement.firstChild, nativeElement);
        }

        // remove the empty element(the host)
        parentElement.removeChild(nativeElement);
    }
}
