import { User } from './user.model';

export class Vote {
  constructor(vote: Vote) {
    Object.assign(this, vote);
  }
  public id?: number;
  public created_at?: Date;
  public updated_at?: Date;
  public user_id: number;
  public product_id: number;
  public user?: User;
  public value? = 0;
  public data?: any;
}
