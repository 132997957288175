import { Component, ElementRef } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeader {
    public user: User;

  constructor(
      private el: ElementRef,
      private tokenService: TokenService
  ) { }

  //wait for the component to render completely
  ngOnInit(): void {
    var nativeElement: HTMLElement = this.el.nativeElement,
    parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
    this.user = this.tokenService.getUser();
  }
}
