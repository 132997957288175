import { PaginatedResponse } from './paginated-response.model';

export class Merchant {
  public id: number;
  public created_at: Date;
  public updated_at: Date;
  public name: string;
}

export class MerchantPaginatedResponse extends PaginatedResponse {
  public data: Merchant[];
}
