import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { TokenService } from '../services/token.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private tokenService: TokenService) {
  }

  /**
   * Determines whether or not the route can be activated or accessed.
   *
   * @returns {boolean}
   */
  public canActivate(): boolean {
    if (this.tokenService.valid()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

  /**
   * Determines whether or not the child routes of the parent can be activated or accessed.
   *
   * @returns {boolean}
   */
  public canActivateChild(): boolean {
    return this.canActivate();
  }

  /**
   * Determines whether the route can be loaded whatsoever.
   *
   * @returns {boolean}
   */
  public canLoad(): boolean {
    var response = this.canActivate();
    console.log('response = ', response);
    return response;
  }
}
