import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

import { Environment, FC_ENV } from '../../models/environment.model';
import { TokenResponse } from '../../models/token.model';
import { TokenService } from '../../services/token.service';
import { LoginService } from '../../services/login.service';


@Component({
    selector: 'fc-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    @ViewChild('loginForm') public form: NgForm;

    constructor(
        @Inject(FC_ENV) public env: Environment,
        protected router: Router,
        protected tokenService: TokenService,
        protected loginService: LoginService,
        protected notify: NotificationsService
    ) {}

    /**
     * Upon page load, check if the user is already authenticated.
     * Redirect to the dashboard if so.
     */
    public ngOnInit() {
        // if (this.tokenService.valid()) {
        //     this.redirect('/');
        // }
    }

    /**
     * Send the form data to the LoginService. Save the token if successful.
     */
    public onSubmit() {
        console.log('this.form.value = ', this.form.value);
        this.loginService
            .authenticate(this.form.value)
            .subscribe(
                (response: TokenResponse) => {
                    this.tokenService.store(response);
                    this.redirect('/');
                },
                (error: any) => {
                    this.notify.error(error.message);
                }
            );
    }

    /**
     * Redirect to a different place.
     *
     * @param {string} path
     */
    public redirect(path: string): Promise<boolean> {
        return this.router.navigate([path]);
    }
}
