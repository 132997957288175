import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../models/role.model';
import { User } from '../models/user.model';


@Injectable()
export class TokenService {
    private jwtHelper = new JwtHelperService();
    private tokenName = 'auth_token';

    /**
     * Returns whether the token is valid (user is authenticated).
     */
    public valid(): boolean {
        // Does the token even exist in localStorage?
        if (!localStorage.getItem(this.tokenName)) {
            return false;
        }

        // If the token hasn't expired, they're authenticated
        return !this.jwtHelper.isTokenExpired(localStorage.getItem(this.tokenName));
    }

    private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    /**
     * Retrieve the token in localStorage.
     */
    public get(): string {
        var token = localStorage.getItem(this.tokenName);
        if (!token) {
            token = this.getCookie(this.tokenName);
        }
        return token;
    }

    /**
     * Retrieve the user from localStorage.
     */
    public getUser(): any {
        var user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            return null;
        }

        // temporary fix while transitioning from user's cached logins
        if (!user.role) {
            let name = 'user';
            if (user.role_id == 2) {
                name = 'admin';
            }
            if (user.role_id == 3) {
                name = 'creator';
            }
            user.role = new Role({
                id: user.role_id,
                name: name
            });
        }

        return user;
    }

    /**
     * Store the token and user in localStorage.
     *
     * @param {object} response
     */
    public store(response: any): void {
        console.log('response = ', response);
        localStorage.setItem(this.tokenName, response.token)
        localStorage.setItem('user', JSON.stringify(response.user));
        console.log(this.tokenName + ' = ', localStorage.getItem(this.tokenName));
        console.log('user = ', localStorage.getItem('user'));
    }

    /**
     * Destroy the token in localStorage.
     */
    public destroy(): void {
        localStorage.removeItem(this.tokenName);
        localStorage.removeItem('user');
    }
}
