import {PaginatedResponse} from './paginated-response.model';
import {User} from './user.model';
import {Merchant} from './merchant.model';
import {Vote} from './vote.model';
import {Image} from './image.model';

export class Product {
  public created_at: Date;
  public description: string;
  public discounted_price: number;
  public image?: Image;
  public id: number;
  public images?: Image[];
  public name: string;
  public price: number;
  public recommended_budget: number;
  public regular_price: number;
  public short_description: string;
  public type: string;
  public updated_at: Date;
  public user?: User;
  public user_defined_budget: false;
  public user_id: number;
}

export class ProductPaginatedResponse extends PaginatedResponse {
  public data: Product[];
}
