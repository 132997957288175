import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html'
})
export class AppAside {
  public user: User;

  constructor(
    private el: ElementRef,
    private router: Router,
    public tokenService: TokenService
  ) { }

  //wait for the component to render completely
  ngOnInit(): void {
    var nativeElement: HTMLElement = this.el.nativeElement,
      parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
    this.user = this.tokenService.getUser();
  }


  /**
   * Logout button.
   */
  public logout(): void {
    this.tokenService.destroy();
    this.router.navigate(['/login']);
  }
}
