import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'si-dashboard',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {
  // public disabled = false;
  // public status: {isopen: boolean} = {isopen: false};
  //
  // public toggled(open: boolean): void {
  //   console.log('Dropdown is now: ', open);
  // }
  //
  // public toggleDropdown($event: MouseEvent): void {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   this.status.isopen = !this.status.isopen;
  // }

  ngOnInit(): void {}
}
