import { Component, ElementRef } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { User } from '../../models/user.model';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app-sidebar.component.html'
})
export class AppSidebar {

    constructor(
        private el: ElementRef,
        public tokenService: TokenService,
        public user: User
    ) { }

    //wait for the component to render completely
    ngOnInit(): void {
        var nativeElement: HTMLElement = this.el.nativeElement,
            parentElement: HTMLElement = nativeElement.parentElement;
        // move all children out of the element
        while (nativeElement.firstChild) {
            parentElement.insertBefore(nativeElement.firstChild, nativeElement);
        }
        // remove the empty element(the host)
        parentElement.removeChild(nativeElement);

        this.user = this.tokenService.getUser();
    }
}
