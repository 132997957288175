import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'si-not-found',
  templateUrl: 'not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {
  }

  public ngOnInit(): void {
    setTimeout(() => { this.redirect(); }, 2500);
  }

  public redirect(): void {
    this.router.navigate(['/']);
  }
}
