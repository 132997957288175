import { InjectionToken } from '@angular/core';

export const FC_ENV: InjectionToken<Environment> = new InjectionToken('cf.environment.configuration');

export class Environment {
    public api: string;
    public app_name: string;
    public app_url: string;
    public production: boolean;
    // public stripe_client_id: string;
    public stripe_key: string;
}
