import { Appointment } from './appointment.model';
import { PaginatedResponse } from './paginated-response.model';
import { Product } from './product.model';
import { Post } from './post.model';
import { Role } from './role.model';
import { Subscription } from './subscription.model';

export class User {
	// constructor(user: User) {
	//     Object.assign(this, user);
	// }
	public appointments?: Appointment[];
	public c_password?: string;
	public created_at: Date;
	public email: string;
	public has_password?: boolean;
	public husband: string;
	public id: number;
	public name: string;
	public password?: string;
	public pivot?: any;
	public posts?: Post[];
	public products_owned?: Product[];
	public products_purchased?: Product[];
	public role: Role;
	public role_id: number;
	public stripe_account?: any[];
	public subscriptions?: Subscription[];
	public updated_at: Date;
	public username: string;
	public webinarTime?: string;
	public wife: string;
}


export class UserPaginatedResponse extends PaginatedResponse {
	public data: User[];
}
