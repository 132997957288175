import { PaginatedResponse } from './paginated-response.model';

export class Role {
    constructor(role?: Role) {
        Object.assign(this, role);
    }
    public id?: number;
    public created_at?: Date;
    public updated_at?: Date;
    public name: string;
}


export class RolePaginatedResponse extends PaginatedResponse {
    public data: Role[];
}
